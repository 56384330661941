<template>
  <div class="container">
    <TitleBarBaseVue />
    <div class="handle_bar">
      <el-row :gutter="20">
        <el-col :xs="7" :sm="3" :md="2" :lg="2" :xl="2" class="flex">
          <el-button type="primary" @click="onAdd">添加</el-button>
        </el-col>
        <el-col :xs="8" :sm="7" :md="5" :lg="4" :xl="3">
          <el-select v-model="params.source" clearable placeholder="选择商品来源" @click="handleSearch">
            <el-option label="全部" value=""></el-option>
            <el-option label="手动添加" value="1"></el-option>
            <el-option label="授权添加" value="2"></el-option>
          </el-select>
        </el-col>
        <el-col :xs="8" :sm="7" :md="5" :lg="4" :xl="3">
          <el-select v-model="params.firstLevelCategoryId" clearable placeholder="选择一级类别" @change="onSecondList">
            <el-option label="全部" value=""></el-option>
            <el-option v-for="item in firstList" :key="item.categoryId" :label="item.name" :value="item.categoryId"></el-option>
          </el-select>
        </el-col>
        <el-col :xs="8" :sm="7" :md="5" :lg="4" :xl="3">
          <el-select v-model="params.secondLevelCategoryId" clearable placeholder="选择二级类别" @change="handleSearch()" :disabled="params.firstLevelCategoryId ? false : true">
            <el-option label="全部" value=""></el-option>
            <el-option v-for="item in secondList" :key="item.categoryId" :label="item.name" :value="item.categoryId"></el-option>
          </el-select>
        </el-col>
        <el-col :xs="24" :sm="10" :md="8" :lg="8" :xl="5" class="flex">
          <el-input v-model="params.keyWords" @change="changeParams" clearable placeholder="请输入名称关键词"> </el-input>
          <el-button class="margin-left-xs" type="primary" @click="handleSearch">搜索</el-button>
        </el-col>
      </el-row>
    </div>
    <el-table :data.sync="tableList" border>
      <el-table-column label-class-name="header" prop="goodsId" label="商品编号" width="100" align="center"></el-table-column>
      <el-table-column prop="name" label="商品名称" align="center"></el-table-column>
      <el-table-column label="商品图片" align="center">
        <template slot-scope="scope">
          <img :src="scope.row.smallImg" alt style="width:48px;height:48px;" />
        </template>
      </el-table-column>
      <el-table-column prop="unit" label="单位" align="center" width="100px">
        <template slot-scope="scope">
          {{ unitList[scope.row.spec] }}
        </template>
      </el-table-column>
      <el-table-column prop="goodsCategory" label="商品类别" align="center"></el-table-column>
      <el-table-column prop="stock" label="商品来源" align="center">
        <template slot-scope="scope">
          {{ scope.row.synchroGoodsId ? '授权添加' : '手动添加' }}
        </template>
      </el-table-column>
      <el-table-column prop="stock" label="商品库存" align="center">
        <template slot-scope="scope">
          {{ scope.row.stock }}
        </template>
      </el-table-column>
      <el-table-column label="状态" width="100" align="center">
        <template slot-scope="scope">
          <el-button @click="onUpdateStatus(scope.row)" title="点击下架" v-if="scope.row.status == 1" size="mini" type="primary">已上架</el-button>
          <el-button @click="onUpdateStatus(scope.row)" title="点击上架" v-else size="mini" type="warning">已下架</el-button>
        </template>
      </el-table-column>
      <el-table-column prop label="操作" align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="success" @click="onEidt(scope.row)" :disabled="scope.row.status == 1 ? true : false">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pageCo">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.pageNo"
        :page-sizes="[1, 2, 5, 10, 20]"
        :page-size="params.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      />
    </div>

    <!-- 修改留存  -->
    <el-dialog title="修改库存" v-if="editVisible" :visible.sync="editVisible" width="550px">
      <div class="margin-right-10">
        <el-form label-position="right" label-width="80px">
          <el-form-item label="库存">
            <el-input v-model="num" v-Int></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer margin-right-10">
        <el-button @click="editVisible = false">取 消</el-button>
        <el-button type="primary" @click="onUpdateStock">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TitleBarBaseVue from '@/components/TitleBar/TitleBarBase.vue'
import { goodsList, goodsStockUpdate, goodsStatusUpdate, categoryList, categorySecondList } from '@/api/GoodsController'
export default {
  data() {
    return {
      params: {
        source: null,
        keyWords: '',
        firstLevelCategoryId: null, //
        secondLevelCategoryId: null,
        pageNo: 1,
        pageSize: 10
      },
      total: 0,

      tableList: [],

      editVisible: false, // 修改弹框
      dtaTaAs: {
        goodsId: ''
      },
      num: '', //修改库存数量

      firstList: [],
      secondList: [],
      unitList: {
        1: '个',
        2: '件',
        3: '瓶',
        4: '套',
        5: '双',
        6: '盒',
        7: '条',
        8: '支',
        9: '箱',
        10: '包',
        11: '片',
        12: '罐'
      }
    }
  },

  mounted() {
    this.fecthList()
    this.onCategoryList()
  },

  methods: {
    changeParams(v) {
      !v && this.handleSearch()
    },

    handleSearch() {
      console.log(this.params)
      this.fecthList()
    },

    async fecthList() {
      let [err, res] = await goodsList(this.params)
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '获取列表失败')
      }
      console.log(res)
      //this.$message.success(res.msg || '获取用户列表成功')
      this.tableList = res.data.records
      this.total = res.data.total
    },
    // 监听 pageSize 发生改变
    handleSizeChange(newSize) {
      this.params.pageSize = newSize
      this.fecthList()
    },
    // 监听 页码值 发生改变
    handleCurrentChange(newPage) {
      this.params.pageNo = newPage
      this.fecthList()
    },

    //获取一级分类
    async onCategoryList() {
      let [err, res] = await categoryList()
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '获取失败')
      }
      console.log(res)

      this.firstList = res.data || []
    },

    //一级分类改变，获取二级分类
    async onSecondList(value) {
      if (value) {
        let param = {
          categoryId: value
        }
        let [err, res] = await categorySecondList(param)
        if (err) {
          console.log(err)
          return this.$message.error(err.msg || '获取二级列表失败')
        }
        console.log(res)
        this.params.secondLevelCategoryId = null
        this.secondList = res.data || []
        this.handleSearch()
      } else {
        this.params.secondLevelCategoryId = null
        this.secondList = []
        this.handleSearch()
      }
    },

    //修改库存弹出
    onEidtStock(row) {
      this.dtaTaAs = { ...row }
      this.num = row.stock
      this.editVisible = true
    },

    // 更新库存
    async onUpdateStock() {
      let num = this.num
      if (num != '') {
        num = num - this.dtaTaAs.stock
        if (num == 0) {
          return this.$message.error('修改前后库存一样')
        }
        let param = {
          goodsId: this.dtaTaAs.goodsId,
          number: Math.abs(num),
          type: num > 0 ? 1 : 0
        }
        let [err, res] = await goodsStockUpdate(param)
        if (err) {
          console.log(err)
          return this.$message.error(err.msg || '操作失败')
        }
        this.fecthList()
        this.editVisible = false
      } else {
        this.$message.error('请填写库存数量')
      }
    },

    //更新上下架
    async onUpdateStatus(row) {
      let param = {
        goodsId: row.goodsId,
        status: row.status == 1 ? 0 : 1
      }
      let [err, res] = await goodsStatusUpdate(param)
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '操作失败')
      }
      this.fecthList()
    },

    //新建
    onAdd() {
      this.$router.push({
        path: '/goodsEdit'
      })
    },

    //编辑
    onEidt(row) {
      this.$router.push({
        path: '/goodsEdit',
        query: {
          goodsId: row.goodsId
        }
      })
    }
  },

  components: {
    TitleBarBaseVue
  }
}
</script>
